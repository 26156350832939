import React, { useRef, useEffect, useState, useMemo } from "react";
import Globe from "react-globe.gl";
import * as THREE from "three";
import ReactDOMServer from "react-dom/server";
import { Checkbox } from "@nextui-org/checkbox";
import { Button } from "@nextui-org/button";
import { Card, CardBody } from "@nextui-org/card";
import { NextUIProvider } from "@nextui-org/react";
import { Dropdown, DropdownTrigger, DropdownMenu } from "@nextui-org/dropdown";
import { Image } from "@nextui-org/image";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  useDisclosure,
} from "@nextui-org/react";

import {
  RenderPass,
} from "postprocessing";

import { UnrealBloomPass } from "three/addons/postprocessing/UnrealBloomPass.js";

import landTopology from "./land_m10.json";

import * as topojson from "topojson-client";

const VenueGlobe = () => {
  const globeEl = useRef();
  const bufferTimeoutRef = useRef(null);

  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const [facetype, setFacetype] = useState(undefined);

  const [places, setPlaces] = useState([]);
  const [pointsData, setPointsData] = useState([]);
  const [selectedIntegrations, setSelectedIntegrations] = useState([
    "opentable_count",
    "resy_count",
    "tock_count",
    "trip_count",
    "resdiary_count",
    "design_count",
    "quandoo_count",
    "fork_count",
    "sevenrooms_count",
  ]);

  // Provider definitions remain for toggles but won't create separate cylinders
  const providerTypes = [
    { key: "opentable_count", color: "#F94144" },
    { key: "resy_count", color: "#F9844A" },
    { key: "tock_count", color: "#277DA1" },
    { key: "trip_count", color: "#F9C74F" },
    { key: "resdiary_count", color: "#4D908E" },
    { key: "design_count", color: "#F8961E" },
    { key: "quandoo_count", color: "#43AA8B" },
    { key: "fork_count", color: "#90BE6D" },
    { key: "sevenrooms_count", color: "#577590" },
  ];

  // Load city labels
  useEffect(() => {
    fetch("/map_labels.geojson")
      .then((res) => res.json())
      .then(({ features }) => setPlaces(features));
  }, []);

  useEffect(() => {
    async function fun() {
      const res = await fetch("/Montserrat_Bold.json");

      const json = await res.json();

      setFacetype(json);
    }

    fun();
  }, []);

  // Load CSV data (unchanged)
  useEffect(() => {
    fetch("/results.csv")
      .then((res) => res.text())
      .then((csv) => {
        const rows = csv.split("\n");
        const headers = rows[0].split(",");
        const data = rows.slice(1).map((row) => {
          const values = row.split(",");
          const point = {};
          headers.forEach((header, index) => {
            point[header] = isNaN(values[index])
              ? values[index]
              : parseFloat(values[index]);
          });
          return point;
        });

        setPointsData(
          data.map((point) => ({
            lat: point.latitude,
            lng: point.longitude,
            counts: providerTypes.reduce((acc, { key }) => {
              acc[key] = point[key] || 0;
              return acc;
            }, {}),
          }))
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Sum selected providers for each point
  const getSelectedSum = (point) => {
    let sum = 0;
    providerTypes.forEach(({ key }) => {
      if (selectedIntegrations.includes(key)) {
        sum += point.counts[key];
      }
    });
    return sum;
  };

  // Compute the max sum (for color/height scale)
  const maxVal = useMemo(() => {
    let max = 0;
    pointsData.forEach((pt) => {
      const val = getSelectedSum(pt);
      if (val > max) max = val;
    });
    return max;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pointsData, selectedIntegrations]);

  // For city-label hover display
  const HoverCard = ({ content }) => (
    <Card
      isBlurred
      className="absolute top-10 left-10 border-none bg-background/60 dark:bg-default-100/50 min-w-[200px] max-w-[800px]"
      shadow="sm"
      style={{
        position: "fixed",
        top: "10px",
        left: "10px",
      }}
    >
      <CardBody>
        <div className="flex flex-col items-left p-4">
          <h1 className="text-large font-medium">Statistics</h1>
          {content.map((c, idx) => (
            <p key={idx} className="text-small text-foreground/80">
              {c}
            </p>
          ))}
        </div>
      </CardBody>
    </Card>
  );

  // Toggle a provider
  const toggleIntegration = (key) => {
    setSelectedIntegrations((prev) =>
      prev.includes(key) ? prev.filter((k) => k !== key) : [...prev, key]
    );
  };

  // Initialize the globe viewpoint and auto-rotate
  useEffect(() => {
    if (globeEl.current && globeEl.current.postProcessingComposer().passes.length === 1) {
      globeEl.current.pointOfView(
        { lat: 51.5072, lng: 0.1276, altitude: 3 },
        0
      );
      globeEl.current.controls().autoRotate = true;
      globeEl.current.controls().autoRotateSpeed = -0.3;
      //globeEl.current.controls().dampingFactor = 0.1;

      const scene = globeEl.current.scene();
      const camera = globeEl.current.camera();

      const composer = globeEl.current.postProcessingComposer();
      composer.addPass(new RenderPass(scene, camera));
      const glitchPass = new UnrealBloomPass(undefined, 0.5);
      composer.addPass(glitchPass);

      //const filmPass = new FilmPass();
      //composer.addPass(filmPass);
    }
  }, []);

  // Filter dropdown UI
  const ToggleContainer = () => (
    <div className="flex flex-col items-left space-y-1 p-3">
      {providerTypes.map(({ key, color }) => (
        <label key={key} style={{ color }}>
          <Checkbox
            type="checkbox"
            isSelected={selectedIntegrations.includes(key)}
            onValueChange={() => toggleIntegration(key)}
          />
          {key.replace("_count", "").toUpperCase()}
        </label>
      ))}
    </div>
  );

  const randomData = [...Array(200).keys()].map(() => ({
    lat: (Math.random() - 0.5) * 180,
    lng: (Math.random() - 0.5) * 360,
    alt: Math.random() * 1.5 + 0.1,
    radius: Math.random() * 0.4,
    color: "#D8B4E2",
  }));

  /**
   * center the title at top of screen, use imported montserrat tyypeface
   */
  return (
    <NextUIProvider className="dark">
      <>
        <Globe
          ref={globeEl}
          waitForGlobeReady={true}
          // Basic setup
          backgroundColor="black"
          atmosphereColor="#4e8fdf"
          atmosphereAltitude={0.5}
          backgroundImageUrl="//unpkg.com/three-globe/example/img/night-sky.png"
          bumpImageUrl="./packed_dirt_normal_map-Dklnx-rB.jpg"
          globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
          // City labels
          labelsData={places}
          labelLat={(d) => d.properties.latitude}
          labelLng={(d) => d.properties.longitude}
          labelText={(d) => d.properties.name}
          labelSize={(d) => Math.sqrt(d.properties.pop_max) * 2e-4}
          labelDotRadius={0}
          labelTypeFace={facetype}
          labelColor={() => "rgba(255, 255, 255, 0.5)"}
          labelAltitude={0.1}
          labelResolution={2}
          onLabelHover={(lbl) => {
            // Move the same hover logic for labels
            if (bufferTimeoutRef.current) {
              clearTimeout(bufferTimeoutRef.current);
              bufferTimeoutRef.current = null;
            }
            if (lbl) {
              globeEl.current.controls().autoRotate = false;
            } else {
              bufferTimeoutRef.current = setTimeout(() => {
                globeEl.current.controls().autoRotate = true;
                globeEl.current.controls().autoRotateSpeed = -0.3;
                bufferTimeoutRef.current = null;
              }, 1000);
            }
          }}
          rendererConfig={{ antialias: false }}
          customLayerData={randomData}
          customThreeObject={(d) => {
            // Create a group for the star and its glow
            const group = new THREE.Group();

            // Star (main sphere)
            const sphereGeometry = new THREE.SphereGeometry(d.radius, 32, 32);
            const sphereMaterial = new THREE.MeshLambertMaterial({
              color: d.color,
            });
            const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
            group.add(sphere);

            // Glow (larger sphere with additive blending)
            const glowGeometry = new THREE.SphereGeometry(
              d.radius * 1.5,
              32,
              32
            );
            const glowMaterial = new THREE.MeshBasicMaterial({
              color: d.color,
              transparent: true,
              opacity: 0.5,
              blending: THREE.AdditiveBlending,
              depthWrite: false,
            });
            const glow = new THREE.Mesh(glowGeometry, glowMaterial);
            group.add(glow);

            // Store parameters for animation:
            group.userData = {
              glowMaterial,
              baseOpacity: 0.5,
              baseScale: 1.5,
              initialLat: d.lat,
              initialLng: d.lng,
              // Increase drift speed for demo; adjust as needed for performance
              latSpeed: (Math.random() - 0.5) * 0.05, // degrees per second
              lngSpeed: (Math.random() - 0.5) * 0.05,
              startTime: Date.now(),
            };

            return group;
          }}
          customThreeObjectUpdate={(obj, d) => {
            const { lat, lng, alt } = d;
            return Object.assign(
              obj.position,
              globeEl.current?.getCoords(lat, lng, alt)
            );
          }}
          polygonsData={
            topojson.feature(landTopology, landTopology.objects.land).features
          }
          polygonSideColor={() => "#0E0E52"}
          polygonCapMaterial={
            new THREE.MeshPhongMaterial({
              color: "#09092c",
              side: THREE.DoubleSide,
            })
          }
          polygonAltitude={0.01}
          globeMaterial={
            new THREE.MeshPhongMaterial({
              opacity: 0.5,
              transparent: true,
              map: new THREE.TextureLoader().load(
                "//unpkg.com/three-globe/example/img/earth-night.jpg"
              ),
            })
          }
          // Hex bin usage
          hexBinPointsData={pointsData}
          // Instead of objectThreeObject, we define a weight for each point:
          hexBinPointWeight={(point) => getSelectedSum(point)}
          // Scale altitude by sumWeight vs. maxVal
          hexAltitude={(hex) =>
            (maxVal > 0 ? hex.sumWeight / maxVal : 0) + 0.02
          }
          // Color from white (#ffffff) to dark red (#8B0000)
          hexTopColor={(hex) => {
            return "#449DD1";
          }}
          hexSideColor={(hex) => {
            return `#449DD1`;
          }}
          hexBinMerge={true}
          enablePointerInteraction={false}
          // Move the same hover logic we used for object hover to hex hover
          onHexHover={(hex) => {
            if (bufferTimeoutRef.current) {
              clearTimeout(bufferTimeoutRef.current);
              bufferTimeoutRef.current = null;
            }
            if (hex) {
              globeEl.current.controls().autoRotate = false;
            } else {
              bufferTimeoutRef.current = setTimeout(() => {
                globeEl.current.controls().autoRotate = true;
                globeEl.current.controls().autoRotateSpeed = -0.3;
                bufferTimeoutRef.current = null;
              }, 1000);
            }
          }}
          // Show a tooltip with the sum of venues
          hexLabel={(hex) => {
            return ReactDOMServer.renderToString(
              <HoverCard
                content={Object.keys(hex.points[0].counts)
                  .filter((key) => hex.points[0].counts[key] > 0)
                  .sort(
                    (a, b) => hex.points[0].counts[b] - hex.points[0].counts[a]
                  )
                  .map(
                    (key) =>
                      `${key.replace("_count", "")}: ${
                        hex.points[0].counts[key]
                      }`
                  )}
              />
            );
          }}
        />

        {/* Additional UI controls/drawer */}
        {!isOpen && (
          <div
            style={{
              position: "absolute",
              bottom: 20,
              left: 20,
              color: "white",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Dropdown
              isBlurred
              className="border-none bg-background/60 dark:bg-default-100/50 max-w-[610px] dark"
            >
              <DropdownTrigger>
                <Button variant="bordered">Filter</Button>
              </DropdownTrigger>
              <DropdownMenu emptyContent={<ToggleContainer />} />
            </Dropdown>
          </div>
        )}

        {!isOpen && (
          <div
            className="dark"
            style={{
              position: "absolute",
              bottom: 20,
              right: 20,
            }}
          >
            <Button
              className="dark"
              onPress={() => onOpen()}
              startContent={
                <Image
                  className="w-6 h-6 rounded"
                  src="https://framerusercontent.com/images/6mLh071sty5bzTNZ6IRiKcCyIoY.png"
                />
              }
              variant="bordered"
            >
              Find out more
            </Button>
          </div>
        )}

        <Drawer
          className="bg-background/60 dark"
          isOpen={isOpen}
          placement={"bottom"}
          onOpenChange={onOpenChange}
        >
          <DrawerContent isBlurred className="text-center p-8">
            {(onClose) => (
              <>
                <DrawerHeader className="dark flex flex-col text-center gap-1 text-white/80 text-2xl wrap">
                  <span className="inline-block text-center">
                    Browse our collection of{" "}
                    <span
                      style={{
                        textShadow: "rgba(255,255,255,0.59) 0px 0px 20px",
                      }}
                      className="text-white text-3xl inline font-bold"
                    >
                      360,000+
                    </span>{" "}
                    bookable venues
                  </span>
                </DrawerHeader>
                <DrawerBody className="text-white/70 space-y-2">
                  <div className="flex flex-col justify-center items-center">
                    {/* ... (your existing drawer content) ... */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="36"
                      viewBox="0 0 24 24"
                    >
                      <g fill="none" stroke="currentColor" strokeWidth="1.5">
                        <path d="m9.213 10.174l2.015-1.605c1.302-1.037 1.953-1.556 2.363-1.3s.197 1.05-.228 2.636l-.04.15c-.154.572-.23.858-.096 1.086l.007.012c.14.226.438.306 1.033.465c1.072.287 1.608.43 1.702.804l.004.019c.083.376-.34.712-1.185 1.386l-2.015 1.604c-1.303 1.038-1.954 1.556-2.364 1.3s-.196-1.05.229-2.636l.04-.15c.153-.571.23-.857.095-1.086l-.007-.012c-.14-.225-.438-.305-1.033-.465c-1.072-.287-1.608-.43-1.702-.804l-.004-.019c-.083-.375.34-.712 1.186-1.385Z" />
                        <path
                          d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12Z"
                          opacity="0.5"
                        />
                      </g>
                    </svg>
                    <p>
                      Become instantly bookable to millions of people using
                      restaurant recommendation apps or who follow social media
                      influencers.
                    </p>
                  </div>
                  {/* ... etc. */}
                </DrawerBody>
                <DrawerFooter className="flex flex-row justify-center">
                  <Button
                    onPress={() =>
                      window.open("https://allthetables.com", "_blank")
                    }
                    variant="light"
                  >
                    Get in touch
                  </Button>
                </DrawerFooter>
              </>
            )}
          </DrawerContent>
        </Drawer>
      </>
    </NextUIProvider>
  );
};

export default VenueGlobe;
